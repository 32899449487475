import React, { useState } from 'react'
import { Container, Tab, Nav, Accordion } from 'react-bootstrap'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import DATA from './constants'
import {
  practice,
  practice_heading,
  practice_content,
  practice_content_list,
  practice_inner,
  practice_tabs,
  practice_link,
  practice_list,
  practice_item,
} from './style.module.scss'

export const Practice = () => {
  const [activeTab, setActiveTab] = useState(DATA[0].tab)
  return (
    <Layout>
      <Container>
        <SEO title="Areas of Practice" />
        <div className={practice}>
          <h1 className={practice_heading}>Areas of Practice</h1>
          <div className={practice_inner}>
            <Tab.Container activeKey={activeTab} onSelect={setActiveTab}>
              <Nav variant="pills" className={practice_tabs}>
                {DATA.map((item) => (
                  <Nav.Item key={item.tab}>
                    <Nav.Link as="button" className={practice_link} eventKey={item.tab}>
                      {item.tab}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content>
                {DATA.map((item) => (
                  <Tab.Pane eventKey={item.tab} key={item.tab}>
                    {activeTab === item.tab && (
                      <Accordion className={practice_list}>
                        {item.data.map(({ title, content }) => (
                          <Accordion.Item className={practice_item} eventKey={title} key={title}>
                            <Accordion.Header>{title}</Accordion.Header>
                            <Accordion.Body className={practice_content}>
                              {Array.isArray(content) ? (
                                <ul className={practice_content_list}>
                                  {content.map((el) => (
                                    <li key={el}>{el}</li>
                                  ))}
                                </ul>
                              ) : (
                                <p>{content}</p>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    )}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </Container>
    </Layout>
  )
}
